import { mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import MarkdownContent from './MarkdownContent';

const FormCardContact = ({
  title, fieldLabels, actionTitle, dataProtectionLabel, contentComponent
}) => {
  const [formData, setFormData] = useState({
    name: '', email: '', phone: '', message: ''
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const encode = (data) => Object.keys(data)
    .map((key) => `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`)
    .join('&');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formData })
    })
      .then(() => setShowSuccessMessage(true))
      .catch((error) => alert(error));

    setFormData({
      name: '', email: '', phone: '', message: ''
    });
  };

  const MarkdownComponent = contentComponent || MarkdownContent;

  return (
    <Container className="py-3 my-0 bg-secondary min-vh-100 d-flex align-items-center" fluid>
      <Container>
        <div className="navHeight mt-3" />
        <h1 className={ showSuccessMessage ? 'invisible' : 'text-primary' }>{title}</h1>
        <Form name="contact" onSubmit={ handleSubmit } className={ showSuccessMessage ? 'invisible mb-5' : 'mb-5' } data-netlify="true" data-netlify-honeypot="bot-field">
          <Row>
            <Form.Control type="hidden" name="form-name" value="contact" />
            <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 5 } xl={ 5 } className="d-flex flex-column justify-content-between">
              <Form.Group controlId="name">
                <Form.Label>{fieldLabels && fieldLabels[0]} *</Form.Label>
                <Form.Control type="text" name="name" defaultValue={ formData.name } onChange={ handleChange } required />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{fieldLabels && fieldLabels[1]} *</Form.Label>
                <Form.Control type="email" name="email" defaultValue={ formData.email } onChange={ handleChange } required />
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>{fieldLabels && fieldLabels[2]}</Form.Label>
                <Form.Control type="phone" name="phone" defaultValue={ formData.phone } onChange={ handleChange } />
              </Form.Group>
            </Col>
            <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 7 } xl={ 7 }>
              <Form.Group controlId="message">
                <Form.Label>{fieldLabels && fieldLabels[3]} *</Form.Label>
                <Form.Control as="textarea" name="message" rows={ 9 } defaultValue={ formData.message } onChange={ handleChange } required />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={ 12 } sm={ 12 } md={ 10 } lg={ 10 } xl={ 10 }>
              <Form.Group controlId="dataProtection" className="h-100 my-auto">
                <Row noGutters className="h-100 my-auto">
                  <Form.Check type="checkbox" inline required />
                  <MarkdownComponent containerClasses="d-inline my-auto" content={ dataProtectionLabel } />
                </Row>
              </Form.Group>
            </Col>
            <Col xs={ 12 } sm={ 12 } md={ 2 } lg={ 2 } xl={ 2 }>
              <Button variant="primary" type="submit" className="float-right">{actionTitle}</Button>
            </Col>
          </Row>
        </Form>
        { showSuccessMessage
        && (
          <Container
            className="position-absolute d-flex align-items-center"
            style={{
              top: 0, left: 0, bottom: 0, right: 0
            }}
          >
            <Container>
              <Row className="justify-content-center">
                <Col xs={ 6 } sm={ 6 } md={ 6 } lg={ 4 } xl={ 4 }>
                  <Icon path={ mdiCheckCircle } className="text-success" />
                </Col>
              </Row>
              <Row className="justify-content-center my-4">
                <Col xs={ 12 } sm={ 12 } md={ 10 } lg={ 8 } xl={ 8 }>
                  <h3 className="text-center">Vielen Dank für das Interesse!</h3>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={ 12 } sm={ 12 } md={ 10 } lg={ 8 } xl={ 8 }>
                  <p className="text-center">Ihre Nachricht ist bei uns eingegangen. Wir werden uns zeitnah bei Ihnen melden.</p>
                </Col>
              </Row>
            </Container>
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default FormCardContact;

FormCardContact.propTypes = {
  title: PropTypes.string.isRequired,
  fieldLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  actionTitle: PropTypes.string.isRequired,
  dataProtectionLabel: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

FormCardContact.defaultProps = {
  contentComponent: null
};