import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import FormCardContact from '../components/FormCardContact';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/MarkdownContent';


export const ContactPageTemplate = ({ form, html, contentComponent }) => (
  <div className="flex-grow-1">
    <FormCardContact
      fieldLabels={ form.fieldLabels }
      actionTitle={ form.actionTitle }
      title={ form.title }
      dataProtectionLabel={ html }
      contentComponent={ contentComponent }
    />
    <iframe
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDB2N1Caz-ueRmyejaMe2dx-_5UcEBl2Ko&q=place_id:ChIJI4HPLaZ4sUcRJhs4eD3rBMU"
      width="100%"
      height="1000"
      title="LocationMap"
      frameBorder="0"
      style={{
        border: 'none', maxHeight: '100vh', display: 'block', padding: '0'
      }}
      allowFullScreen={ false }
      aria-hidden="false"
    />
  </div>
);

ContactPageTemplate.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired,
    fieldLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    actionTitle: PropTypes.string.isRequired
  }).isRequired,
  html: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

ContactPageTemplate.defaultProps = {
  contentComponent: null
};

const ContactPage = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark;
  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.description }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <ContactPageTemplate
        form={ frontmatter.form }
        html={ html }
        contentComponent={ HTMLContent }
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.node,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactById($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "contact-page" }, language: { eq: $language } }) {
      fields {
        slug
      }
      html
      frontmatter {
        language
        title
        description
        form {
          title
          fieldLabels
          actionTitle
        }
      }
    }
  }
`;